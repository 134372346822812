import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Img from "gatsby-image";
import ReactPlayer from "react-player/youtube";

export const Container = styled.div`
    padding-top: 0;
    padding-bottom: 0;
    @media (min-width: ${breakpoints.sm}) {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }
`;
export const ContentsContainer = styled.div`
    padding-bottom: 4rem;
`;
export const Title = styled.h2`
    padding-bottom: 1rem;
    padding-left: 0.5rem;
`;
export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    font-size: 0.9rem;

    @media (min-width: ${breakpoints.lg}) {
        flex-flow: row;
        font-size: 1rem;
    }
`;
export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 0;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
    @media (min-width: ${breakpoints.sm}) {
        flex-direction: row;
    }
`;
export const InfoWrapper = styled.div`
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 25rem;
    @media (min-width: ${breakpoints.sm}) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1rem;
        max-width: none;
    }
    @media (min-width: ${breakpoints.lg}) {
        flex: 0 0 70%;
        max-width: 70%;
    }
`;
export const InfoBox = styled.div`
    margin: 0 auto 0 0.5rem;
    @media (min-width: ${breakpoints.sm}) {
        min-width: 17rem;
        margin: 0;
    }
`;
export const Info = styled.div`
    margin-left: 0.5rem;
    width: 18rem;
    min-width: 18rem;
    @media (min-width: ${breakpoints.sm}) {
        width: 17rem;
        min-width: 17rem;
    }
`;
export const InfoTitle = styled.p`
    margin: 0;
    font-weight: 700;
`;
export const InfoContents = styled.p``;

export const Video = styled(ReactPlayer)`
    height: 100% !important;
    width: 100% !important;
`;
export const VideoWrapper = styled.div`
    width: 100%;
    height: 15rem;
    margin-bottom: 6rem;

    @media (min-width: ${breakpoints.sm}) {
        height: 20rem;
    }
    @media (min-width: ${breakpoints.md}) {
        height: 30rem;
    }
    @media (min-width: ${breakpoints.lg}) {
        height: 35rem;
    }
    @media (min-width: ${breakpoints.xl}) {
        height: 40rem;
    }
`;
export const ImageContainer = styled.div`
    width: 100%;
    max-width: 25rem;
    min-width: 13.75rem;
    position: relative;
    overflow: hidden;
    margin: 2rem auto;
    flex: 0 0 100%;
    @media (min-width: ${breakpoints.sm}) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1rem;
        margin: 0;
    }
    @media (min-width: ${breakpoints.lg}) {
        flex: 0 0 30%;
        max-width: 30%;
    }
`;
export const StyledImage = styled(Img)`
    transition: transform 0.5s;
`;
